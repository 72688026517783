import React from 'react'

export default function NavLinear({ className }) {
    return (
        <svg className={`${className}`} xmlns="http://www.w3.org/2000/svg" width="135" height="42" viewBox="0 0 135 42" fill="none">
            <path d="M130.5 37C42.5 37 112 5 5 5" stroke="url(#paint0_linear_135_1663)" strokeWidth="9" strokeLinecap="round" />
            <defs>
                <linearGradient id="paint0_linear_135_1663" x1="-46.8312" y1="20.9893" x2="148.128" y2="20.9893" gradientUnits="userSpaceOnUse">
                    <stop offset="0.339396" stopColor="#FC786D" stopOpacity="0.7" />
                    <stop offset="0.493969" stopColor="#FBBC05" stopOpacity="0.7" />
                    <stop offset="0.660135" stopColor="#51D875" stopOpacity="0.7" />
                    <stop offset="0.790011" stopColor="#68A7F2" />
                </linearGradient>
            </defs>
        </svg>)
}
