import React, { useEffect, useState } from "react";
import BurgerIcon from "../assets/Navbar/BurgerIcon";
import BackIcon from "../assets/Navbar/BackIcon";
import LogoMobile from "../assets/Navbar/LogoMobile";
import Link from "next/link";
import About from "./About";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import Search from "../assets/Search";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, navTabs }) => {
  // ======== states ===========
  const [search, setSearch] = useState("");

  // ======== useEffects ===========
  useEffect(() => {
    const body = document.body.style;
    isSidebarOpen ? (body.overflow = "hidden") : (body.overflow = "auto");
  }, [isSidebarOpen]);

  // ======== variables ===========
  const t = useTranslations("navbar");
  const router = useRouter();

  // ======== Functions ===========
  const handleSubmit = (e) => {
    setIsSidebarOpen(false);
    e.preventDefault();
    router.push(`/search?input=${search}`);
  };

  return (
    <div className="flex items-center z-[10000] lg:hidden">
      <span onClick={() => setIsSidebarOpen(true)}>
        <BurgerIcon className="cursor-pointer " />
      </span>
      <div
        className={` fixed top-0 bottom-0 bg-white pb-10 overflow-y-scroll  ${
          isSidebarOpen ? "sidebar-container-open" : "sidebar-container-close"
        } ${router.locale == "ar" ? "left-0" : "right-0"}`}
        style={{ boxShadow: "0px 4px 20px #01013f20" }}
      >
        <div className="ml-auto w-fit mt-3 mr-9 cursor-pointer">
          <span onClick={() => setIsSidebarOpen(false)}>
            <BackIcon />
          </span>
        </div>
        <div className="w-fit mx-auto mt-9">
          <LogoMobile />
        </div>

        {/* search form */}
        <form
          className="w-[260px] mt-10  grid grid-cols-12 my-3 mx-auto rounded-[4px] h-12 "
          onSubmit={handleSubmit}
        >
          <input
            placeholder={t("search")}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            className="col-span-10 p-4 bg-[#FAFAFA] text-black active:border-none outline-none border-transparent focus:border-transparent focus:ring-0"
          />
          <button
            className=" bg-[#FAFAFA] flex justify-center  relative  col-span-2"
            type="submit"
          >
            <Search className=" absolute -top-1 " />
          </button>
        </form>

        {/* Links */}
        <div className="flex flex-col items-center space-y-6 mt-10">
          {navTabs.map((tab, i) => {
            if (tab.title !== t("about_us")) {
              return (
                <div
                  className="h-9 w-[150px] "
                  key={i}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <Link href={tab.url} key={i}>
                    <div className="text-[24px] text-[#010140] font-[510] flex justify-center mx-auto ">
                      {tab.title}
                    </div>
                  </Link>
                </div>
              );
            } else
              return (
                <div className="w-[160px] flex justify-center" key={i}>
                  <About type="sidebar" setIsSidebarOpen={setIsSidebarOpen} />
                </div>
              );
          })}
        </div>

        {/* Request a Quote */}
        <div
          className="relative cursor-pointer z-10 mx-auto w-fit h-[45px] mt-16"
          onClick={() => setIsSidebarOpen(false)}
        >
          <Link href="./contact">
            <button className=" text-[#010140] font-[510] text-[16px] custom-rounded-border w-[240px] h-[64px]">
              {t("quote")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
