import "../styles/globals.css";
import { useState, useEffect } from "react";
import { hasCookie, getCookies, setCookie } from "cookies-next";
import Layout from "../layout/index";
import Script from "next/head";
import Analytics from "../components/Analytics/Analytics";
import { NextIntlProvider } from "next-intl";
import messages from "../locales/en.json";
import Head from "next/head";
import NextApp from "next/app";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
  const [cookiesBox, setCookiesBox] = useState(false);
  const [googleAnalytivs, setGoogleAnalytivs] = useState(false);
  const router = useRouter();
  if (typeof window !== "undefined" && "serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {});
    });
  }

  useEffect(() => {
    const { isCookiesbox } = getCookies("isCookiesbox");
    const { acceptAnalytivs } = getCookies("acceptAnalytivs");
    isCookiesbox ? setCookiesBox(false) : setCookiesBox(true);
    acceptAnalytivs == "true" ? setGoogleAnalytivs(true) : "";
  }, [cookiesBox]);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <meta name="application-name" content="Ascot Enterprise Solutions" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="apple-mobile-web-app-title"
          content="Ascot Enterprise Solutions"
        />
        <meta
          name="description"
          content="Ascot - Trusted IT Solutions Provider for Driving Business Growth"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/favicon.ico" />
        <meta name="msapplication-TileColor" content="#010140" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="apple-touch-icon" href="/favicon.ico" />

        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.ascot.ws" />
        <meta name="twitter:title" content="Ascot Enterprise Solutions" />
        <meta
          name="twitter:description"
          content="Ascot - Trusted IT Solutions Provider for Driving Business Growth"
        />
        <meta name="twitter:image" content="https://www.ascot.ws/favicon.ico" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Ascot - Innovative Solutions for Business Growth"
        />
        <meta
          property="og:description"
          content="Ascot - Trusted IT Solutions Provider for Driving Business Growth"
        />
        <meta property="og:site_name" content="Ascot Enterprise Solutions" />
        <meta property="og:url" content="https://www.ascot.ws" />
        <meta property="og:image" content="https://www.ascot.ws/favicon.ico" />
        <meta name="google" content="notranslate" />

        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link
          rel="icon"
          href="/favicon.ico"
          type="image/<generated>"
          sizes="<generated>"
        />
        <link
          rel="apple-touch-icon"
          href="/favicon.ico"
          type="image/<generated>"
          sizes="<generated>"
        />

        <link rel="shortcut icon" type="image/x-icon" href="favicon.ico" />
      </Head>
      {googleAnalytivs ? (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROD}`}
          />
          <Script strategy="lazyOnload">
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROD}', {
        page_path: window.location.pathname,
        });
    `}
          </Script>
        </>
      ) : (
        ""
      )}

      <NextIntlProvider messages={{ ...messages, ...pageProps.messages }}>
        <Head>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          {/* <link
            href="/public/fonts/alfont_com_NeoSansW23-Bold.ttf"
            rel="stylesheet"
          /> */}

          {/* Local Font */}
          {/* {lang === "ar" ? (
            <link
              href="/public/fonts/alfont_com_NeoSansW23-Bold.ttf"
              rel="stylesheet"
            />
          ) : (
            <link href="/public/fonts/SF-Pro.ttf" rel="stylesheet" />
          )} */}
        </Head>
        <Layout>
          <Component {...pageProps} />
          <Analytics cookiesBox={cookiesBox} setCookiesBox={setCookiesBox} />
        </Layout>
      </NextIntlProvider>
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async function getInitialProps(context) {
  const { locale } = context.router;
  return {
    ...(await NextApp.getInitialProps(context)),
    messages: require(`../locales/${locale || "en"}.json`),
  };
};
