import React from 'react';

function BackIcon({ className }) {
    return (
        <div>
            <svg
                className={className}
                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#010140" />
                <path d="M22.4019 16.9841C22.318 16.9841 22.2276 16.9841 22.1437 16.9841C16.2422 16.9841 13.0615 16.9841 7.16002 16.9841C6.50789 16.9841 6.09465 16.6715 6.01072 16.1151C5.92678 15.5837 6.34647 15.0586 6.90175 15.0023C7.01152 14.9898 7.12774 14.996 7.2375 14.996C13.1196 14.996 16.268 14.996 22.1501 14.996C22.2341 14.996 22.318 14.996 22.4149 14.996C22.389 14.8773 22.3051 14.8397 22.247 14.7772C20.523 13.1018 18.7926 11.4326 17.0622 9.75709C16.4682 9.18193 16.6554 8.30044 17.4238 8.05662C17.837 7.92533 18.1986 8.02536 18.5085 8.32545C19.0121 8.81308 19.5093 9.29446 20.0129 9.7821C21.8725 11.5826 23.732 13.3831 25.5916 15.1836C26.1404 15.715 26.134 16.2651 25.5851 16.7965C23.2349 19.0722 20.8846 21.3478 18.5343 23.6234C18.2373 23.911 17.9016 24.0736 17.469 23.9673C16.6942 23.7735 16.4165 22.8795 16.9653 22.3168C17.3398 21.9292 17.7402 21.5604 18.1276 21.1853C19.5029 19.8536 20.8781 18.522 22.2534 17.1967C22.3116 17.1404 22.3826 17.1029 22.4471 17.0591C22.4342 17.0341 22.4149 17.0091 22.4019 16.9841Z" fill="#010140" />
            </svg>

        </div>
    );
}

export default BackIcon;