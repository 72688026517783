import React from 'react';

function BurgerIcon({ className }) {
    return (
        <div>
            <svg
                className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="8" width="20" height="2.66667" rx="1.33333" fill="#010140" />
                <rect x="6" y="14.666" width="20" height="2.66667" rx="1.33333" fill="#010140" />
                <rect x="6" y="21.334" width="20" height="2.66667" rx="1.33333" fill="#010140" />
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#010140" />
            </svg>

        </div>
    );
}

export default BurgerIcon;