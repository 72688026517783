import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/footer";
import { useRouter } from "next/router";

export default function index({ children }) {
  const router = useRouter();
  return (
    <div
      dir={router.locale == "ar" ? "rtl" : "ltr"}
      className={`${router.locale == "ar" ? "font-ar" : "font-en"}  relative`}
    >
      <div className="fixed top-0 right-0 left-0 bg-white z-[2000]">
        <Navbar />
      </div>
      {children}
      <Footer />
    </div>
  );
}
