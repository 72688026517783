import React from "react";

export default function Search({className}) {
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 22C26.8056 22 23 25.8056 23 30.5C23 35.1944 26.8056 39 31.5 39C33.4864 39 35.3149 38.3176 36.7617 37.176L41.5858 42C41.9764 42.3905 42.6095 42.3905 43.0001 42C43.3906 41.6095 43.3906 40.9763 43.0001 40.5858L38.176 35.7618C39.3176 34.3149 40.0001 32.4865 40.0001 30.5C40.0001 25.8056 36.1944 22 31.5 22ZM25 30.5C25 26.9102 27.9101 24 31.5 24C35.0898 24 38.0001 26.9102 38.0001 30.5C38.0001 32.2952 37.2735 33.9188 36.0961 35.0962C34.9188 36.2736 33.2952 37 31.5 37C27.9101 37 25 34.0899 25 30.5Z"
        fill="#010140"
      />
    </svg>
  );
}
