import React, { useState } from "react";
import { setCookie } from "cookies-next";

export default function Analytics({ cookiesBox, setCookiesBox }) {
  return (
    <div
      className={` ${
        cookiesBox ? "block" : "hidden"
      } bottom-0 fixed shadow-2xl	z-[5000] m-5 rounded-[50px] bg-white float-left h-[20rem] xlg:w-[30rem] w-[100%] `}
    >
      <div className=" flex flex-col items-center justify-evenly h-[90%]  ">
        <div className="w-[80%] m-auto text-center">
          <p className="text-[32px] leading-[40px] mb-[20px]">
            COOKIES SETTING
          </p>

          <p>
            We use cookies and similar technologies to help personalize content,
            tailor and measure ads, and provide a better experience. By clicking
            accept, you agree to this, as outlined in our Cookie Policy.
          </p>
        </div>
        <div className="flex gap-[16px]">
          <button
            onClick={() => {
              setCookie("isCookiesbox", true);
              setCookie("acceptAnalytivs", true);
              setCookiesBox(false);
            }}
            className=" custom-rounded-border-coookies  -black h-[35px] w-[90px]"
          >
            Accept
          </button>
          <button
            onClick={() => {
              setCookiesBox(false);
              setCookie("isCookiesbox", false);
              setCookie("acceptAnalytivs", false);
              setCookiesBox(false);
            }}
            className="border-2 rounded-[36px]  border-[#010140] h-[64px] w-[150px]"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
}

``;
