import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import DownArrow from "../assets/DownArrow";

const option = (title, url, setIsSidebarOpen = null) => {
  return (
    <Link href={url}>
      <div
        className="float-none cursor-pointer text-[#010140] px-5 h-[36px] flex items-center w-full text-left lg:hover:bg-[#ddd] font-[510] text-[10px] xlg:text-[14px]"
        style={{ boxShadow: "box-shadow: #00000006px 4px 12px" }}
        onClick={() => (setIsSidebarOpen ? setIsSidebarOpen(false) : "")}
      >
        {title}
      </div>
    </Link>
  );
};

const About = ({ type, setIsSidebarOpen }) => {
  const t = useTranslations("navbar");

  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  if (type == "navbar") {
    return (
      <div className="dropdown hover:block  flex flex-col justify-center">
        <button className="dropbtn w-full gap-2 lg:flex items-center justify-center hidden ">
          <Link href="/about_us">
            <a
              className={`pl-1 h-9 flex items-center text-[12px] xlg:text-[16px] w-fit
                             ${
                               router.pathname == "/about_us"
                                 ? "font-[700] text-[#6D2E88]"
                                 : "font-[510] text-[#010140]"
                             } `}
            >
              {t("about_us")}
            </a>
          </Link>
          <DownArrow className="w-[8px] mt-1 dropdownArrow" />
        </button>
        <div className="dropdown-content hidden absolute bg-[#f9f9f9] w-[120px] xlg:w-[150px] h-fit py-2 z-[1] hover:flex justify-around items-center rounded-[5px]">
          {option(t("about"), "/about_us")}
          {option(t("our_team"), "/about_us/our_team")}
          {/* {option(t("testimonials"), "/about_us/Testmonials")} */}
        </div>
        {router.pathname == "/about_us" && (
          <span className="pl-[9px] about_underline">
            <hr className="w-[50px] xlg:w-[60px] h-0 border-b-[2px] xlg:border-b-[3px] absolute  border-[#010140]   rounded-full  " />
          </span>
        )}
      </div>
    );
  } else
    return (
      <div
        className={` flex flex-col  justify-start items-center w-[150px] ${
          isOpen ? "sidebar-about-close" : "sidebar-about-open"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <button className="dropbtn w-fit flex items-center justify-center gap-2 ">
          <div
            className={`
                         h-9 flex items-center text-[24px] w-max font-[510] text-[#010140]
                            } `}
          >
            {t("about_us")}
          </div>
          <DownArrow
            className={` w-[11px] mt-1 ml-3 ${
              isOpen ? "downArrow" : "  upArrow"
            } `}
          />
        </button>
        <div className="dropdown-content [&>*]:justify-center [&>*]:pl-0  flex flex-col  h-fit [&>*]:text-[16px]  py-2 ">
          {option(t("about"), "/about_us", setIsSidebarOpen)}
          {option(t("our_team"), "/about_us/our_team", setIsSidebarOpen)}
          {/* {option(t("testimonials"), "/about_us/Testmonials", setIsSidebarOpen)} */}
        </div>
      </div>
    );
};

export default About;
