import React from "react";

export default function Language() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM18 10C18 10.6906 17.9125 11.3608 17.748 12H14.41C14.469 11.3538 14.5 10.6849 14.5 10C14.5 9.3151 14.469 8.6462 14.41 8H17.748C17.9125 8.6392 18 9.3094 18 10ZM14.1256 6H16.9297C16.046 4.47231 14.6716 3.26398 13.0221 2.59049C13.4904 3.53438 13.8689 4.69453 14.1256 6ZM6.97794 2.59049C5.32837 3.26398 3.95398 4.47231 3.07026 6H5.87444C6.13111 4.69453 6.50959 3.53438 6.97794 2.59049ZM5.59002 8H2.25204C2.08751 8.6392 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25204 12H5.59002C5.53099 11.3538 5.5 10.6849 5.5 10C5.5 9.3151 5.53099 8.6462 5.59002 8ZM5.87444 14H3.07026C3.95398 15.5277 5.32837 16.736 6.97794 17.4095C6.50959 16.4656 6.13111 15.3055 5.87444 14ZM13.0221 17.4095C14.6716 16.736 16.046 15.5277 16.9297 14H14.1256C13.8689 15.3055 13.4904 16.4656 13.0221 17.4095ZM12.5 10C12.5 10.6943 12.4652 11.3635 12.4008 12H7.59918C7.53484 11.3635 7.5 10.6943 7.5 10C7.5 9.3057 7.53484 8.6365 7.59918 8H12.4008C12.4652 8.6365 12.5 9.3057 12.5 10ZM12.0822 6C11.8907 5.14167 11.6428 4.38215 11.3581 3.74966C11.0204 2.99913 10.6701 2.51413 10.3818 2.24316C10.2431 2.11273 10.1393 2.05118 10.079 2.02377C10.031 2.00192 10.008 2.00012 10.0017 2H9.9983C9.992 2.00012 9.969 2.00192 9.921 2.02377C9.8607 2.05118 9.7569 2.11273 9.6182 2.24316C9.3299 2.51413 8.9796 2.99913 8.6419 3.74966C8.3572 4.38215 8.1093 5.14167 7.91779 6H12.0822ZM7.91779 14C8.1093 14.8583 8.3572 15.6179 8.6419 16.2503C8.9796 17.0009 9.3299 17.4859 9.6182 17.7568C9.7569 17.8873 9.8607 17.9488 9.921 17.9762C9.9689 17.998 9.9919 17.9999 9.9983 18H10.0017C10.0081 17.9999 10.0311 17.998 10.079 17.9762C10.1393 17.9488 10.2431 17.8873 10.3818 17.7568C10.6701 17.4859 11.0204 17.0009 11.3581 16.2503C11.6428 15.6179 11.8907 14.8583 12.0822 14H7.91779Z"
          fill="#010140"
        />
      </svg>
    </div>
  );
}
