import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  const router = useRouter();
  const [lang, setLang] = useState(router.locale);

  useEffect(() => {
    setLang(router.locale);
  }, [router.locale]);

  const showToast = (type = null, message) => {
    let position;
    if (lang === "ar") {
      position = toast.POSITION.TOP_LEFT;
    } else {
      position = toast.POSITION.TOP_RIGHT;
    }
    toast[type](message, {
      position,
      className: `toast-message-${type}`,
      pauseOnFocusLoss: false,
    });
  };

  return showToast;
};

export default useToast;
