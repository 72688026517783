import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import CustomBtn from "../btn/buttonCustom";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import axios from "../../Axios/index";
import useToast from "../Toast/useToast";
import { ToastContainer, toast } from "react-toastify";
function Footer() {
  const showToast = useToast();
  const router = useRouter();
  const [lang, setLang] = useState(router.locale);
  const [email, setEmail] = useState("");
  const t = useTranslations("footer");

  useEffect(() => {
    setLang(router.locale);
  }, [router.locale]);

  const subscribe = async () => {
    const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(checkEmail)) {
      try {
        await axios.post("user/subscribe/", {
          email,
        });
        router.locale == "en"
          ? showToast("success", "thank you for subscribe")
          : showToast("success", "شكرا للاشتراك ");
      } catch (error) {
        showToast("error", "Please try agian later");
        router.locale == "en"
          ? showToast("error", "Please try agian later")
          : showToast("error", "الرجاء المحاولة لاحقا");
      }
    } else {
      router.locale == "en"
        ? showToast("error", "check your email")
        : showToast("error", "الرجاء التاكد من البريد الاكتروني ");
    }
  };

  const currentYear = new Date().getFullYear()
  console.log(currentYear)

  return (
    <>
      <footer
        aria-label="Site Footer"
        className="bg-[#010140] text-white pb-10  relative z-10 "
      >
        <div className="bg-gradient-to-r from-[#6232EA] pb-[5px] via-[#FE17FF] to-[#2EBBFF] w-full" />
        <div className="mx-auto responsive lg:pt-16 mt-10 responsive pb-6 xl:px-0 max-w-[90rem]">
          <div className=" grid gap-6 pt-4 grid-cols-12">
            <div className="text-center flex lg:gap-0 gap-6 lg:flex-col md:flex-col responsive  sm:text-left lg:col-span-4 md:col-span-4 col-span-12 relative">
              <div
                className={`mb-6 col-span-4 lg:w-[171px] relative lg:ml-0 ${lang == "ar" ? "-mr-10" : "-ml-7"
                  } lg:h-[55px] w-[300px] h-[100px]`}
              >
                <Image
                  alt="Ascot Logo"
                  src={"/footer/footerLogo.svg"}
                  layout="fill"
                />
              </div>
              <div
                className={`col-span-1 ${lang == "ar" ? "text-right" : "text-left"
                  }`}
              >
                <ul className="lg:space-y-4 space-y-2">
                  <li className="lg:mt-2 lg:text-[18px] text-[12px] font-[400]">
                    <span>{t("address")}</span>
                  </li>
                  <li className="lg:mt-2 lg:text-[18px] text-[12px] font-[400]">
                    <span>
                      <a href="mailto:info@ascot.ws">Info@ascot.ws</a>
                    </span>
                  </li>
                  <li
                    dir="ltr"
                    className="lg:mt-2 lg:text-[18px] text-[12px] font-[400]"
                  >
                    <span>
                      <a href="tel:+96265334707">+96265334707</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`${lang == "ar" ? "text-right" : "text-left"
                } col-span-12 md:col-span-4 lg:col-span-4`}
            >
              <p className="lg:text-[24px] text-[18px] leading-[21.48px] font-[590] text-white">
                {t("usefulLinks")}
              </p>

              <nav aria-label="Footer Services Nav" className="lg:mt-8 mt-4">
                <ul className="lg:text-[16px] text-[12px] lg:flex lg:flex-col lg:space-y-5 lg:h-full lg:gap-0 gap-3 flex flex-wrap flex-col justify-start h-[100px]">
                  <li>
                    <Link
                      className="text-white transition hover:text-white/75"
                      href={"/about_us"}
                    >
                      {t("aboutUs")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white transition hover:text-white/75"
                      href={"/contact"}
                    >
                      {t("contactUs")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="text-white transition hover:text-white/75"
                      href={"/about_us/Testmonials"}
                    >
                      {t("testimonials")}
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="text-white transition hover:text-white/75"
                      href={"/careers"}
                    >
                      {t("careers")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white transition hover:text-white/75"
                      href={"/terms"}
                    >
                      {t("termsAndConditions")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={` lg:col-span-4 col-span-12 md:col-span-4`}>
              <p className="lg:text-[24px] lg:leading-[28.64px] font-[590] text-[18px] leading-[21.48px] text-white">
                {t("subscribeNewsletter")}
              </p>
              <p className="leading-[32px] lg:text-[16px] text-[12px] font-[400] text-white">
                {t("subscribeText")}
              </p>
              <div className="mx-auto lg:mt-8 lg:max-w-md">
                <form className="mt-4 max-w-screen">
                  <div className="flex flex-col gap-4 lg:flex-col lg:items-start">
                    <div className="w-full rounded-[10px] bg-[#010140]">
                      <input
                        className=" w-full rounded-[10px] border-[1px] text-white  bg-[#FFFFFf] bg-opacity-[7%] px-3 py-3 mb-3 shadow-sm"
                        type="email"
                        placeholder={t("enterEmail")}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className=" w-max mt-2  flex justify-start items-center "
                    onClick={() => {
                      subscribe();
                    }}
                  >
                    <CustomBtn
                      txt={t("subButton")}
                      txtColor={"white"}
                      dark={false}
                      href={false}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-[#02022B] pt-2">
        <div className="mx-auto items-center  flex flex-col lg:flex-row max-w-[90rem]  pb-6 bg-[#02022B] pt-6 justify-between responsive">
          <ul className="lg:mt-4 flex flex-row gap-8 lg:flex-row lg:flex  justify-center lg:gap-8  sm:mt-0 sm:justify-start">
            <li className="cursor-pointer">
              <a href="https://www.facebook.com/ASCOTESJO/" target="_blank">
                <Image
                  alt="share on Facebook"
                  src={"/footer/footerFB.svg"}
                  width={"12px"}
                  height={"19px"}
                />
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href={
                  "https://www.linkedin.com/company/ascot-enterprise-solutions-company/"
                }
                target="_blank"
              >
                <Image
                  alt="share on Twitter"
                  src={"/footer/footerLinked.png"}
                  width={"18px"}
                  height={"19px"}
                />
              </a>
            </li>

            <li className="cursor-pointer">
              <a href="https://twitter.com/ascotsolution" target="_blank">
                <Image
                  alt="share on Twitter"
                  src={"/footer/footerTW.svg"}
                  width={"18px"}
                  height={"19px"}
                />
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href={"https://www.youtube.com/@ascotenterprisesolutions"}
                target="_blank"
              >
                <Image
                  alt="share on YouTube"
                  src={"/footer/footerYT.svg"}
                  width={"14px"}
                  height={"19px"}
                />
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href={
                  "https://www.instagram.com/ascot_enterprise_solutions/?hl=en"
                }
                target="_blank"
              >
                <Image
                  alt="share on Instagram"
                  src={"/footer/footerIG.svg"}
                  width={"16px"}
                  height={"19px"}
                />
              </a>
            </li>
            <li className="cursor-pointer">
              <Link href={"/about_us"}>
                <Image
                  alt="Wifi Image"
                  src={"/footer/footerwifi.svg"}
                  width={"14px"}
                  height={"19px"}
                />
              </Link>
            </li>
          </ul>
          <p className="text-center lg:text-sm text-[10px] text-white sm:text-left">
            {lang == "ar" ? ` حقوق الطبع والنشر © ${currentYear} جميع الحقوق محفوظة | Ascot Enterprise Solutions.`
              : `Copyright © ${currentYear} All rights reserved | Ascot Enterprise Solutions.`}
          </p>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Footer;
