import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const ArrowCircle = ({
  href = null,
  txt,
  dark = true,
  bgColor = null,
  onClick = null,
  className = "",
}) => {
  // ========== states ==========
  const [arrowColer, setArrowColer] = useState(dark ? "#010140" : "white");
  const [circleColer, setCircleColer] = useState("transparent");
  const [isHover, setIsHover] = useState(false);

  // ========== useEffects ==========

  const router = useRouter();
  useEffect(() => {
    if (!dark) {
      setCircleColer(arrowColer == "white" ? "transparent" : "white");
    } else if (bgColor) {
      setCircleColer(bgColor);
      setArrowColer("#ffffff");
    } else {
      setCircleColer(arrowColer == "white" ? "#010140" : "transparent");
    }
  }, [arrowColer]);

  const Button = () => {
    return (
      <motion.div
        className={`flex  items-center ${
          bgColor ? `hover:bg-[${bgColor}]` : ""
        } ${className}  `}
        whileHover={{ gap: "5px" }}
        transition={{ type: "spring", stiffness: 600, damping: 10 }}
        onMouseOver={() => {
          bgColor
            ? setCircleColer(bgColor)
            : setArrowColer(dark ? "white" : "#010140");
        }}
        onMouseOut={() => {
          bgColor
            ? setCircleColer("transparent")
            : setArrowColer(dark ? "#010140" : "white");
        }}
        onClick={onClick}
      >
        <div
          className={`cursor-pointer rounded-full`}
          style={{ background: circleColer }}
        >
          <svg
            style={{
              transform: router.locale == "ar" ? "rotate(180deg)" : null,
            }}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.40758 33.3869 2.92347e-07 28.7468 0 24C-2.92347e-07 19.2533 1.40757 14.6131 4.04473 10.6663C6.68188 6.71954 10.4302 3.6434 14.8156 1.82689C19.201 0.0103886 24.0266 -0.464892 28.6822 0.461153C33.3377 1.3872 37.6141 3.67298 40.9706 7.02943L39.2735 8.72649C36.2527 5.70568 32.4039 3.64848 28.2139 2.81504C24.024 1.9816 19.6809 2.40935 15.734 4.0442C11.7872 5.67906 8.41369 8.44758 6.04026 11.9997C3.66682 15.5518 2.4 19.7279 2.4 24C2.4 28.2721 3.66682 32.4482 6.04026 36.0003C8.4137 39.5524 11.7872 42.3209 15.734 43.9558C19.6809 45.5906 24.024 46.0184 28.214 45.185C32.4039 44.3515 36.2527 42.2943 39.2735 39.2735L40.9706 40.9706Z"
              fill={bgColor ? bgColor : dark ? "#010140" : "white"}
            />

            <path
              d="M34.6823 25.2301C34.5816 25.2301 34.4731 25.2301 34.3724 25.2301C27.2906 25.2301 23.4738 25.2301 16.392 25.2301C15.6095 25.2301 15.1136 24.8394 15.0129 24.1439C14.9121 23.4796 15.4158 22.8232 16.0821 22.7529C16.2138 22.7372 16.3533 22.7451 16.485 22.7451C23.5435 22.7451 27.3216 22.7451 34.3802 22.7451C34.4809 22.7451 34.5816 22.7451 34.6978 22.7451C34.6668 22.5966 34.5661 22.5497 34.4964 22.4715C32.4276 20.3772 30.3511 18.2907 28.2746 16.1964C27.5618 15.4774 27.7865 14.3755 28.7085 14.0708C29.2044 13.9067 29.6383 14.0317 30.0102 14.4068C30.6146 15.0164 31.2112 15.6181 31.8155 16.2276C34.047 18.4782 36.2785 20.7289 38.5099 22.9795C39.1685 23.6437 39.1608 24.3314 38.5022 24.9957C35.6818 27.8402 32.8615 30.6848 30.0412 33.5293C29.6848 33.8888 29.2819 34.0919 28.7628 33.9591C27.833 33.7168 27.4998 32.5993 28.1584 31.896C28.6078 31.4115 29.0882 30.9505 29.5531 30.4816C31.2034 28.817 32.8538 27.1525 34.5041 25.4958C34.5739 25.4255 34.6591 25.3786 34.7366 25.3239C34.7211 25.2926 34.6978 25.2614 34.6823 25.2301Z"
              fill={arrowColer}
            />
          </svg>
        </div>
        <motion.p
          className={`font-[510] text-[16px] leading-[40px] ${
            dark ? "text-[#010140]" : "text-white"
          } cursor-pointer px-1`}
        >
          {txt}
        </motion.p>
      </motion.div>
    );
  };

  if (href) {
    return (
      <Link href={href}>
        <a>
          <Button />
        </a>
      </Link>
    );
  } else return <Button />;
};

export default ArrowCircle;
