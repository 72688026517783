import React, { useState } from "react";
import Ascot_logo from "../assets/Ascot_logo";
import Link from "next/link";
import Language from "../assets/Language";
import Search from "../assets/Search";
import About from "./About";
import Sidebar from "./Sidebar";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import axios from "../../Axios";
import NavLinear from "../assets/academy/NavLinear";
export default function Navbar() {
  const t = useTranslations("navbar");
  const router = useRouter();
  const lang = router.locale;

  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navTabs = [
    { title: t("home"), url: "/" },
    { title: t("about_us"), url: "/about_us" },
    { title: t("services"), url: "/services" },
    { title: t("projects"), url: "/projects" },
    { title: t("blog"), url: "/blogs" },
    //enable this line for ascot academy
    // { title: t("academy"), url: "/ascotAcademy" }
  ];

  const createTab = (navName, url) => {
    if (navName !== t("about_us")) {
      return (
        <div className="flex flex-col justify-center items-center relative">
          <Link href={url}>
            <a
              className={` h-9 flex items-center text-[12px] xlg:text-[16px] w-fit ${
                router.pathname == url
                  ? "font-[700] text-[#6D2E88]"
                  : "font-[510] text-[#010140]"
              } `}
            >
              {navName}
            </a>
          </Link>

          {url == router.pathname && (
            <hr className="w-[50px] xlg:w-[60px] h-0 border-b-[2px] xlg:border-b-[3px] border-[#010140] rounded-full absolute bottom-0" />
          )}
        </div>
      );
    } else {
      return <About type="navbar" />;
    }
  };

  const handleLang = () => {
    const { pathname, asPath, query } = router;

    router.push({ pathname, query }, asPath, {
      locale: lang == "ar" ? "en" : "ar",
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const text = document.getElementById("searchInput").value;
    try {
      const { data } = await axios.get(`user/search`, {
        params: {
          text: text,
        },
      });
      const result = data.result;
      if (
        result?.blog.length == 0 &&
        result?.career.length == 0 &&
        result?.product.length == 0 &&
        result?.project.length == 0
      ) {
        router.push("/noresult");
      } else {
        router.push(`/search?input=${text}`);
      }
    } catch (error) {
      router.push("/404");
    }
  };

  return (
    <div className=" flex justify-between px-[45px] items-center xsm:gap-0 mds:gap-[70px]">
      <div
        className="h-8 min-w-[32px]  flex items-center justify-center lg:hidden border-[1px] border-[#010140] rounded-[4px] cursor-pointer"
        onClick={handleLang}
      >
        <p
          className={`text-[14px] text-[#010140]  font-[510] flex items-center  font-sans w-fit h-fit ${
            lang == "ar" ? "py-[5px]" : "pb-[6px]"
          }`}
        >
          {lang == "en" ? "ع" : "E"}
        </p>
      </div>
      {/* logo */}
      <Link href="/">
        <div className="hover:cursor-pointer w-[120px] xlg:w-[160px] h-[72px] flex items-center">
          <Ascot_logo className="w-[120px] xlg:w-[160px]" />
        </div>
      </Link>

      <div className="lg:flex items-center justify-between hidden  max-w-[100rem] mx-auto w-full">
        <div className="flex items-center z-10">
          {/* Links */}
          <div className=" lg:flex justify-center items-center gap-6 xl:gap-12 hidden">
            {navTabs.map((tab, i) => {
              return (
                <span key={i}>
                  {createTab(tab.title, tab.url)}
                  {tab.title == t("academy") && (
                    <NavLinear className={`absolute top-4 -z-10`} />
                  )}
                </span>
              );
            })}
          </div>
        </div>

        <div className="lg:flex items-center gap-x-6 xl:gap-x-8 hidden">
          {/* Language */}
          <div
            className="flex gap-[10px]  items-center cursor-pointer z-10"
            onClick={handleLang}
          >
            <p
              className={` w-fit hidden xlg:block ${
                lang == "en" ? "font-ar" : "font-en"
              }`}
            >
              {t("lang")}
            </p>
            <span>
              <Language />
            </span>
          </div>

          {/* Search */}
          <div className="cursor-pointer z-10 flex overflow-hidden justify-start  navSearchInput h-fit items-center relative">
            <span className="min-w-[24px] h-[45px] flex justify-center  ">
              <Search className=" h-[60px] object-cover absolute -top-2 " />
            </span>

            <form
              onSubmit={handleSearch}
              className="flex items-center border-b-[1px] border-[#010140] border-opacity-20"
            >
              <input
                type="text"
                name=""
                className="h-5 w-[151px] border-none text-[10px]  outline-none flex items-center bg-transparent"
                placeholder={t("search")}
                id="searchInput"
              />
              <button
                type="submit"
                className="font-[510] text-[10px] leading-3 text-[#36A9E1]"
                onClick={handleSearch}
              >
                {t("search")}
              </button>
            </form>
          </div>

          {/* Request a Quote */}
          <Link href="/contact">
            <div className=" cursor-pointer z-10 hide-at-1200 custom-rounded-border w-[160px] h-[48px] flex justify-center items-center">
              <button className=" text-[#010140] font-[510]  ">
                {t("quote")}
              </button>
            </div>
          </Link>
        </div>
      </div>

      {/* Sidebar */}
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isAboutOpen={isAboutOpen}
        setIsAboutOpen={setIsAboutOpen}
        navTabs={navTabs}
      />
    </div>
  );
}
